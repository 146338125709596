import React, { createContext, useState } from 'react';

export interface FirstLoadedContextValue {
  isFirstLoad: boolean;
  setIsFirstLoad: (isFirstLoad) => void;
}

// We assume this FirstLoadedContext will never be used unless inside
// the FirstLoadedContext.Provider, and so the default is never used.
export const FirstLoadedContext = createContext<FirstLoadedContextValue>(
  {} as FirstLoadedContextValue,
);

interface FirstLoadedProviderProps {
  children: React.ReactNode;
}

export const FirstLoadedProvider = (props: FirstLoadedProviderProps) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  return (
    <FirstLoadedContext.Provider
      value={{
        isFirstLoad,
        setIsFirstLoad,
      }}
    >
      {props.children}
    </FirstLoadedContext.Provider>
  );
};
