import { ViewCategory } from '../templates/ViewCategory';
import { getPageDocumentUrl } from '../utils/sanity';
import { getUsedViewCategories } from '../utils/utils';

interface Page {
  slug: {
    current: string;
  };
  _id: string;
}

interface ContextSEO {
  seo?: {
    canonicalPage?: {
      __typename: string;
    };
    hideFromSitemap?: boolean;
  };
}

export interface PagesCreationQueryData {
  views: {
    nodes: Array<
      // TODO migration: insight to view
      Page & { __typename: 'SanityInsight' } & ContextSEO & {
          categories: Array<ViewCategory>;
        }
    >;
  };
  projects: {
    nodes: Array<Page & { __typename: 'SanityProject' }>;
  };
  pages: {
    nodes: Array<Page & { __typename: 'SanityPage' } & ContextSEO>;
  };
}

export const pagesCreationGraphqlQuery = `
fragment ContextSEO on SanitySeo {
  canonicalPage {
    __typename
  }
  hideFromSitemap
}
{
  # TODO migration: insight to view
  views: allSanityInsight {
    nodes {
      __typename
      _id
      slug {
        current
      }
      seo {
        ...ContextSEO
      }
      categories {
        __typename
        _id
        slug {
          current
        }
        seo {
          ...ContextSEO
        }
      }
    }
  }
  projects: allSanityProject {
    nodes {
      __typename
      _id
      slug {
        current
      }
    }
  }
  pages: allSanityPage {
    nodes {
      __typename
      _id
      slug {
        current
      }
      seo {
        ...ContextSEO
      }
    }
  }
}
`;

const pageCreationDataGroqFragment = `
  _type,
  slug {
    current
  },
  _id,
  seo {
    ...
  }
`;

export const pagesCreationGroqQuery = `
  {
    "views": {
      "nodes": *[_type == "insight"] {
        ${pageCreationDataGroqFragment},
        categories[]-> {
          ${pageCreationDataGroqFragment}
        }
      }
    },
    "projects": {
      "nodes": *[_type == "project"] {
        ${pageCreationDataGroqFragment}
      }
    },
    "pages": {
      "nodes": *[_type == "page"] {
        ${pageCreationDataGroqFragment}
      }
    }
  }
  `;

interface PageCreationConfig {
  path: string;
  component: string;
  context?: Record<string, unknown>;
}

export function getPagesToCreate(
  pagesCreationQueryData: PagesCreationQueryData,
): Array<PageCreationConfig> {
  const { pages, projects, views } = pagesCreationQueryData;
  function getPageBaseContext(page: Page & ContextSEO) {
    return {
      id: page._id,
      slug: page.slug.current,
      isCanonical: !!page.seo?.canonicalPage,
      hideFromSitemap: !!page.seo?.hideFromSitemap,
    };
  }

  return [
    ...views.nodes
      .filter(view => view.slug?.current)
      .map(view => ({
        path: getPageDocumentUrl(view),
        component: './src/templates/View.tsx',
        context: getPageBaseContext(view),
      })),

    ...getUsedViewCategories(views.nodes)
      .filter(viewCategory => viewCategory.slug?.current)
      .map(viewCategory => ({
        path: getPageDocumentUrl(viewCategory),
        component: './src/templates/ViewCategory.tsx',
        context: getPageBaseContext(viewCategory),
      })),

    ...projects.nodes
      .filter(project => project.slug?.current)
      .map(project => ({
        path: getPageDocumentUrl(project),
        component: './src/templates/Project.tsx',
        context: getPageBaseContext(project),
      })),

    ...pages.nodes
      .filter(page => page.slug?.current)
      .map(page => ({
        path: getPageDocumentUrl(page),
        component: './src/templates/Page.tsx',
        context: getPageBaseContext(page),
      })),
  ];
}
