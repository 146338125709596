import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Mona-Sans', sans-serif;
  src: url('./assets/MonaSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 32px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  animation: rotate 2s linear 0s infinite;
  transform-origin: center center;
  width: 60px;
  height: 60px;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-family: 'Mona-Sans', sans-serif;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 52px;
  }
`;

function PreviewLoadingScreen() {
  return (
    <>
      <GlobalStyle />
      <Container>
        <ContentContainer>
          <Icon>
            <FaSpinner />
          </Icon>
          <Title>Loading Preview...</Title>
        </ContentContainer>
      </Container>
    </>
  );
}

export default PreviewLoadingScreen;
