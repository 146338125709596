const heading = {
  fontFamily: 'heading',
  fontWeight: 'heading',
};

export const text = {
  body: {
    fontSize: ['body.md', null, null, null, null, 'body.md+'],
    lineHeight: 'body',
  },
  heading: {
    ...heading,
    fontSize: ['heading.xxs', null, null, 'heading.xs', 'heading.sm', 'heading.md'],
  },
  h1: {
    ...heading,
    fontSize: ['heading.xs', null, 'heading.sm', 'heading.md', 'heading.lg', 'heading.xl'],
  },
  giant: {
    ...heading,
    fontSize: ['heading.sm', null, 'heading.md', 'heading.lg', 'heading.xl', 'heading.xxl'],
  },
  blockquote: {
    position: 'relative',
    m: 0,
    my: '1em',
    pl: 'sm',
    '::before': {
      content: "''",
      position: 'absolute',
      top: 'xxs',
      bottom: 'xxs',
      left: 0,
      width: '4px',
      bg: 'black',
    },
  },
  categoryHeading: {
    fontSize: 'var(--small-font)',
    textTransform: 'uppercase',
    mb: 'sm',
  },
  //   sectionDivider: {
  //     fontSize:
  //   }
};
