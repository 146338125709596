import { PageDocumentSlugOnly, PageTypeToPathFunction } from './types/types';
import { urlJoin } from './utils/utils';

export const SITE_NAME = 'ThreeTenSeven';
export const DEFAULT_SITE_DOMAIN = 'threetenseven.co';
export const DEFAULT_SITE_BASE_URL = 'https://threetenseven.co/';

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG] as const;

// WARNING When updating these three constants update in site constants too
export const VIEWS_PREFIX_URL = 'views';
export const PROJECTS_PREFIX_URL = 'work';
export const WORK_PAGE_SLUG = PROJECTS_PREFIX_URL;

export const PAGE_DOCUMENT_TYPES = ['page', 'insight', 'project', 'category'] as const; // TODO migration: insight to view

export const TRANSITION_COLORS = ['var(--tts-blue)', 'var(--tts-red)', 'var(--tts-mustard)'];

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  page: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  insight: (viewDoc: PageDocumentSlugOnly) => urlJoin(VIEWS_PREFIX_URL, viewDoc.slug.current),
  category: (viewCategory: PageDocumentSlugOnly) =>
    urlJoin(VIEWS_PREFIX_URL, viewCategory.slug.current),
  project: (projectDoc: PageDocumentSlugOnly) =>
    urlJoin(PROJECTS_PREFIX_URL, projectDoc.slug.current),
} as const satisfies PageTypeToPathFunction;

export const OPEN_GRAPH_DESCRIPTION_MAX_LENGTH = 300;
export const VIDEO_TIMEOUT_MS = 2000;

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;
