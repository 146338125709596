export const layout = {
  container: {
    px: 'sm',
  },
  hero: {
    variant: 'container',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    pt: 'xs',
    pb: 'sm',
    borderBottom: 'divider',
    minHeight: '70vh',
    // minHeight: ["hero.mobile", null, "hero.desktop"], //! not working on build
    h1: {
      m: 0,
      mb: 'md',
      maxWidth: 'max.xl',
    },
    p: { maxWidth: 'max.lg' },
    'div:not(:first-of-type)': { pb: 'xs' },
    img: { width: '100%', height: 'auto' },
  },
  sectionBanner: {
    variant: 'container',
    borderBlock: 'divider',
    paddingBlock: 'xs',
    justifyContent: 'space-between',
    '*': {
      fontSize: 'body.md+',
    },
    'h2, h3, h4, h5': { fontSize: 'body.md+' },
  },
  visuallyHidden: {
    position: 'absolute',
    clip: 'rect(1px, 1px, 1px, 1px)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
  },
};
