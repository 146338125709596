export const forms = {
  input: {
    bg: 'grey.light',
    border: 'none',
    borderRadius: 0,
    height: 'md',
    px: 'sm',
    maxWidth: 'xxl',
    ':focus': { outline: 'none' },
    // ":focus:not(:focus-visible)": { outline: "none" }  //? not working as expected
    '::placeholder': { fontSize: 'body.sm' },
  },
};
