import React from 'react';
import styled from 'styled-components';
import indicator from './assets/indicator.svg';

const Container = styled.div`
  position: fixed;
  top: 6px;
  right: 6px;
  background: rgba(201, 255, 175, 1);
  box-shadow: 0px 4.44444px 4.44444px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #447308;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.8;

  @media (min-width: 520px) {
    top: 12px;
    right: 12px;
    width: 38px;
    height: 38px;
  }
`;

const IndicatorIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;

  @media (min-width: 520px) {
    width: 22px;
  }
`;

export default function PreviewIndicator() {
  return (
    <Container title="Preview Mode">
      <IndicatorIcon src={indicator} alt=""></IndicatorIcon>
    </Container>
  );
}
