import { WindowLocation } from '@reach/router';
import React, { createContext } from 'react';

// We assume this LocationContext will never be used unless inside
// the LocationContext.Provider, and so the default is never used.
export const LocationContext = createContext<WindowLocation>({} as WindowLocation);

interface LocationProviderProps {
  location: WindowLocation;
  children: React.ReactNode;
}

export const LocationProvider = (props: LocationProviderProps) => {
  return (
    <LocationContext.Provider value={props.location}>{props.children}</LocationContext.Provider>
  );
};
