import { createClient, QueryParams } from '@sanity/client';
import { useLiveQuery } from '@sanity/preview-kit';
import { PageProps } from 'gatsby';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getPagesToCreate,
  pagesCreationGroqQuery,
  PagesCreationQueryData,
} from '../pagesCreation/pagesCreation';
import { uncapitalize } from '../utils/nodash';
import {
  addRawToPortableTextKeys,
  addReferencePathToQueryNode,
  addTypenameKeys,
  areQueryNodesEqual,
  checkPathExistsInData,
  findPathsInObjectByPredicate,
  getGroqQueryFromTree,
  parseGroqQuery,
  removeDraftsFromId,
} from '../utils/sanity';
import { urlJoin } from '../utils/utils';
import { PreviewErrorBoundary } from './PreviewErrorBoundary';
import PreviewLoadingScreen from './PreviewLoadingScreen';
import { usePreviewState } from './PreviewStateContext';

interface PreviewOptions<QueryResult> {
  groqQuery: string;
  getPageData?: () => QueryResult;
  queryParams?: ((defaultData: QueryResult) => QueryParams) | QueryParams | null;
  preprocessPreviewData?: (previewData: QueryResult) => QueryResult;
}

export function usePreviewData<QueryResult>(
  defaultData: QueryResult,
  options: PreviewOptions<QueryResult>,
): QueryResult | null {
  const { queryParams: queryParamsOrFunc, preprocessPreviewData = previewData => previewData } =
    options;
  const queryParams =
    typeof queryParamsOrFunc === 'function' ? queryParamsOrFunc(defaultData) : queryParamsOrFunc;

  const [currentQuery, setCurrentQuery] = useState(options.groqQuery);
  const nTimesFollowedRefs = useRef(0);

  useEffect(() => {
    if (currentQuery !== options.groqQuery) {
      setCurrentQuery(options.groqQuery);
    }
  }, [options.groqQuery]);

  const [previewData, sanityPreviewIsLoading] = useLiveQuery<QueryResult | null>(
    null,
    currentQuery,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    queryParams
      ? // If one the params is the sanity id, remove the "drafts." part if present
        // as the sanity client is using previewDrafts perspective where ids don't have that part.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        { ...queryParams, ...(queryParams.id ? { id: removeDraftsFromId(queryParams.id) } : {}) }
      : undefined,
  );

  const { previewActive, setPreviewIsLoading } = usePreviewState();

  useEffect(() => {
    setPreviewIsLoading(sanityPreviewIsLoading);
  }, [sanityPreviewIsLoading]);

  if (sanityPreviewIsLoading) {
    return null;
  }

  if (nTimesFollowedRefs.current >= 10) {
    console.error('Preview loop > 10');
    return defaultData;
  }

  if (previewData) {
    const preprocessedPreviewData = addMockGatsbyImageData(
      addTypenameKeys(addRawToPortableTextKeys(preprocessPreviewData(previewData))),
    );
    // Check if there are still references to resolve in the data, that exist in defaultData.
    // If there are, update the groq query to fetch these references,
    // and rerender with the new data.
    const referencePaths = findPathsInObjectByPredicate(
      preprocessedPreviewData,
      obj =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        typeof obj === 'object' &&
        obj !== null &&
        '_ref' in obj &&
        typeof obj._ref === 'string' &&
        (obj._type === 'reference' ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          obj._ref.match(/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/)),
    ).filter(path => checkPathExistsInData(defaultData, path));

    if (referencePaths.length > 0) {
      const queryNode = parseGroqQuery(currentQuery);
      const newQueryNode = parseGroqQuery(currentQuery);

      for (const referencePath of referencePaths) {
        const referencePathWithoutRaw = referencePath.map(pathEl =>
          pathEl.startsWith('_raw') ? uncapitalize(pathEl.slice(4)) : pathEl,
        );
        addReferencePathToQueryNode(newQueryNode, referencePathWithoutRaw);
      }

      if (areQueryNodesEqual(queryNode, newQueryNode)) {
        console.warn({
          currentQuery,
          queryParams,
          previewData,
          defaultData,
          preprocessedPreviewData,
          referencePaths,
          newQueryNode,
          newQuery: getGroqQueryFromTree(newQueryNode),
        });
        console.error('Preview query loop found!');
        return defaultData;
      }

      nTimesFollowedRefs.current += 1;

      setCurrentQuery(getGroqQueryFromTree(newQueryNode));

      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return preprocessedPreviewData;
  }

  if (previewActive) {
    // The preview is active, so we should have previewData sooner or later,
    // return null so that the loading screen is shown.
    return null;
  }

  return defaultData;
}

export function withPagePreview<
  QueryResult = object,
  PageContextType = object,
  LocationState = object,
  ServerDataType = object,
>(
  options: PreviewOptions<QueryResult>,
  WrappedComponent: React.ComponentType<
    PageProps<QueryResult, PageContextType, LocationState, ServerDataType>
  >,
): React.ComponentType<PageProps<QueryResult, PageContextType, LocationState, ServerDataType>> {
  const WithPagePreview = ({
    data: pageData,
    ...restProps
  }: PageProps<
    QueryResult,
    PageContextType,
    LocationState,
    ServerDataType
  >): React.ReactElement => {
    const { previewActive } = usePreviewState();

    if (options.getPageData) {
      pageData = options.getPageData();
    }

    if (!previewActive) {
      return <WrappedComponent data={pageData} {...restProps} />;
    }

    const { queryParams: queryParamsOrFunc, ...restOptions } = options;
    const queryParams =
      typeof queryParamsOrFunc === 'function'
        ? queryParamsOrFunc(pageData)
        : queryParamsOrFunc || {};

    const data = usePreviewData<QueryResult>(pageData, {
      queryParams: {
        ...queryParams,
        ...restProps.pageContext,
      },
      ...restOptions,
    });

    if (!data) {
      return <PreviewLoadingScreen></PreviewLoadingScreen>;
    }

    return (
      <PreviewErrorBoundary data={data}>
        <WrappedComponent data={data} {...restProps} />
      </PreviewErrorBoundary>
    );
  };
  WithPagePreview.displayName = WrappedComponent.displayName;
  return WithPagePreview;
}

export function getSanityPreviewClient({
  projectId,
  dataset,
}: {
  projectId: string;
  dataset: string;
}) {
  return createClient({
    withCredentials: true,
    projectId: projectId,
    dataset: dataset,
    ignoreBrowserTokenWarning: true,
    apiVersion: '2023-10-23',
    useCdn: false, // to ensure data is fresh
    perspective: 'previewDrafts',
  });
}

export function usePreviewPagesOn404(pageProps: PageProps<unknown>) {
  const { previewActive } = usePreviewState();

  if (previewActive) {
    const [pageTemplatesByPath, setPageTemplatesByPath] = useState<Record<
      string,
      React.ComponentType<any>
    > | null>(null);

    useEffect(() => {
      import('../pagesCreation/pageTemplatesMap')
        .then(pageTemplatesMap => {
          setPageTemplatesByPath(pageTemplatesMap.pageTemplatesByPath);
        })
        .catch(err => console.error(err));
    }, []);

    const pathname = new URL(window.location.href).pathname;

    const data = usePreviewData<PagesCreationQueryData | null>(null, {
      groqQuery: pagesCreationGroqQuery,
    });

    const pagesToCreate = useMemo(
      () => data && getPagesToCreate(data),
      [!!data, JSON.stringify(data)],
    );

    if (!data || !pageTemplatesByPath) {
      return <PreviewLoadingScreen></PreviewLoadingScreen>;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const matchedPage = pagesToCreate!.find(page => urlJoin(pathname) === urlJoin(page.path));
      if (matchedPage) {
        const Component = pageTemplatesByPath[matchedPage.component];
        return (
          // @ts-expect-error
          <Component data={null} {...pageProps} pageContext={matchedPage.context}></Component>
        );
      }
    }
  }
  return null;
}

export function addMockGatsbyImageData(data: any): any {
  if (Array.isArray(data)) {
    return data.map(el => addMockGatsbyImageData(el));
  } else if (data !== null && typeof data === 'object') {
    const newData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, addMockGatsbyImageData(value)]),
    );
    if (newData.asset && typeof newData.asset === 'object' && 'url' in newData.asset) {
      return {
        ...newData,
        asset: {
          ...newData.asset,
          gatsbyImageData: {
            images: {
              fallback: {
                src: newData.asset.url,
              },
              sources: [],
            },
            backgroundColor: '#04acdc',
            height: 0.5625,
            layout: 'fullWidth',
            width: 1,
          },
        },
      };
    } else {
      return newData;
    }
  }
  return data;
}
