import { buttons } from './variants/buttons';
import { forms } from './variants/forms';
import { layout } from './variants/layout';
import { text } from './variants/text';

const spacings = {
  0: 0,
  xxs: '5px',
  xs: '10px',
  sm: '20px',
  md: '40px',
  lg: '100px',
  xl: '200px',
  xxl: '300px',
};

const theme = {
  breakpoints: ['0px', '768px', '1024px', '1440px', '1920px'],
  space: { ...spacings, touchOffset: '-11px' },
  sizes: {
    ...spacings,
    touch: '44px',
    max: { lg: '910px', xl: '1200px' },
    hero: {
      mobile:
        'calc(100vh - var(--nav-height) - (var(--page-outer-gap) * 3) - (var(--page-outer-gap) * 3) - (var(--body-size) * 1.15) - (var(--body-size) * 1.15))',
      desktop:
        'calc(100vh - var(--nav-height) - var(--intro-links-height) - (var(--page-outer-gap) * 3))',
    },
  },
  fonts: {
    body: '"Visuelt Pro Regular, sans-serif',
    heading: '"Visuelt Pro Medium, sans-serif',
  },
  fontSizes: {
    body: {
      xs: '12px',
      sm: '16px',
      md: '21px',
      'md+': '24px',
      lg: '48px',
      li: '18px',
    },
    heading: {
      xxs: '30px',
      xs: '36px',
      sm: '52px',
      md: '64px',
      lg: '80px',
      xl: '100px',
      xxl: '130px',
    },
  },
  fontWeights: {
    body: 400,
    heading: 400,
    demi: 600, // currently displaying at 700 ?
    bold: 700,
  },
  lineHeights: {
    1: 1,
    blockquote: 1.15,
    body: 1.25,
    heading: 1.09,
  },
  letterSpacing: {
    // --h1-spacing: -0.44px;
    // --h2-spacing: -0.75px;
    // --body-spacing: 0;
  },
  colors: {
    black: '#171D1F',
    // brand: "colors.black",
    // text: "colors.black",
    // primary: "colors.black",
    'black--half': 'hsla(54, 15%, 11%, 0.5)',
    highlight: 'FFEF47',
    accent: '#55FF3F',
    muted: '#D8D8D8',
    bg: {
      light: '#fff',
      highlight: 'colors.highlight',
      dark: '#222030',
    },
    grey: { light: '#EAEAEA', mid: '#C7CDD6', dark: '#8B8E8F' },
  },
  borders: {
    blockquote: '4px solid var(--theme-ui-colors-black)',
    divider: '1px solid var(--theme-ui-colors-black)',
    subtle: '1px solid var(--theme-ui-colors-black--half)',
  },
  zIndices: {
    hide: -1,
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  radii: {
    0: 0,
    circle: '100%',
  },
  ...layout,
  ...text,
  ...buttons,
  ...forms,
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
  },
};

export default theme;
