import { GatsbyBrowser } from 'gatsby';
import React, { useEffect } from 'react';
import PageTransition, { PAGE_TRANSITION_DURATION_MS } from './src/components/PageTransition';
import { FirstLoadedProvider } from './src/context/firstLoadedContext';
import { LocationContext } from './src/context/locationContext';
import PreviewConnectorProvider from './src/preview/PreviewConnectorProvider';
import { PreviewStateProvider } from './src/preview/PreviewStateContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  // If we don't have prevRouterProps that means it's either a first page open
  // or a refresh, and in both cases there are no page transitions so we can
  // use the default behavior.
  if (!prevRouterProps) {
    return true;
  }

  // Save the position to scroll to here, since inside the setTimeout the value
  // we get is not always the one we want (not sure why it's different though).
  const positionToScrollToAfterPageTransition = getSavedScrollPosition(routerProps.location)[1];

  /**
   * We need to delay the scrollTo call because of page transitions.
   * We add 100 ms to avoid race conditions and make sure the call happens on the new page, not the old.
   */
  window.setTimeout(() => {
    window.scrollTo(0, positionToScrollToAfterPageTransition);
  }, PAGE_TRANSITION_DURATION_MS / 2 + 100);

  return false;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  useEffect(() => {
    // Disable automatic scroll restoration so we can handle it ourselves in
    // shouldUpdateScroll due to page transitions
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <GlobalStateProvider>
      <LocationContext.Provider value={props.location}>
        <PreviewStateProvider>
          <PreviewConnectorProvider>
            <FirstLoadedProvider>
              <PageTransition>{element}</PageTransition>
            </FirstLoadedProvider>
          </PreviewConnectorProvider>
        </PreviewStateProvider>
      </LocationContext.Provider>
    </GlobalStateProvider>
  );
};
