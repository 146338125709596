import { forms } from './forms';

export const buttons = {
  primary: { color: 'black' },
  icon: {
    size: 'touch',
  },
  input: {
    ...forms.input,
  },
};
