import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import alertIcon from './assets/alert-circle.svg';

interface PreviewErrorScreenProps {
  title: string;
  message: React.ReactNode;
  onTryAgain?: () => void;
}

const GlobalStyle = createGlobalStyle`  
 @font-face {
  font-family: 'Mona-Sans', sans-serif;
  src: url('./assets/MonaSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans', sans-serif;
  src: url('./assets/MonaSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  font-family: 'Mona-Sans', sans-serif;
  background-color: #fff2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  max-width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextContainer = styled.div`
  color: #4b4b4b;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const ErrorSpan = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #a0a0a0;
  gap: 4px;
`;

const Divider = styled.div`
  width: 16px;
  height: 1px;
  background-color: #a0a0a0;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  font-family: 'Mona-Sans', sans-serif;
`;

const Message = styled.p`
  margin: 0;
  font-size: 18px;
  font-family: 'Mona-Sans', sans-serif;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-top: 16px;
  background: #4b4b4b;
  border: none;
  border-radius: 2px;
  color: white;
  font-size: 16px;
  font-family: 'Mona-Sans', sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2b2b2b;
  }
`;

export default function PreviewErrorScreen({
  title,
  message,
  onTryAgain,
}: PreviewErrorScreenProps) {
  return (
    <>
      <GlobalStyle />
      <Container>
        <ContentContainer>
          <img src={alertIcon}></img>
          <TextContainer>
            <ErrorSpan>
              <Divider></Divider> Preview error
            </ErrorSpan>
            <Title>{title}</Title>
            <Message>{message}</Message>
            {onTryAgain && (
              <Button
                onClick={() => {
                  if (onTryAgain) {
                    onTryAgain();
                  }
                }}
              >
                Try again
              </Button>
            )}
          </TextContainer>
        </ContentContainer>
      </Container>
    </>
  );
}
