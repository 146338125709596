import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react';

import { Module } from '../components/content/FlexibleContent';
import { SITE_DOMAIN, TRANSITION_COLORS } from '../constants';
import { ViewCategory } from '../templates/ViewCategory';
import { DataLayer } from '../types/globals';
import { flatten, uniqBy } from './nodash';
import { getPortableTextAsString } from './sanity';

export function useRunOnceWhenVisible(ref, callback) {
  const hasRanBefore = useRef(false);

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && !hasRanBefore.current) {
          hasRanBefore.current = true;
          callback();
        }
      });

      observer.observe(ref);
      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [ref]);
}

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (process.env.NODE_ENV === 'development') {
    const mockDataLayer = [];
    const origPush = mockDataLayer.push;
    mockDataLayer.push = (...data) => {
      console.log('Pushing data to dataLayer: ', ...data);
      return origPush.call(mockDataLayer, ...data);
    };
    func(mockDataLayer);
  }

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

export function useStoreFormFieldsToLocalStorage(fieldsByName: any) {
  if (typeof window !== 'undefined' && window.localStorage) {
    useEffect(
      () => {
        const storedFieldsObj = loadFormFieldsFromLocalStorage();
        const newFieldsObj = {
          ...storedFieldsObj,
          ...Object.fromEntries(
            Object.entries(fieldsByName).map(([fieldName, field]: any) => [fieldName, field.value]),
          ),
        };
        const newFieldValues = Object.entries(newFieldsObj);
        localStorage.setItem(
          'form_fields',
          JSON.stringify(
            newFieldValues
              .map(([fieldName, fieldValue]: any) => {
                let finalValue = fieldValue;
                let type = 'string';
                if (Array.isArray(finalValue)) {
                  finalValue = JSON.stringify(finalValue);
                  type = 'array';
                } else if (finalValue === null || finalValue === undefined) {
                  return finalValue;
                } else if (typeof finalValue !== 'string') {
                  throw new Error('Got unexpected type: ' + typeof finalValue);
                }
                return [fieldName, finalValue, type];
              })
              .filter(Boolean),
          ),
        );
      },
      Object.values(fieldsByName).map((field: any) => field.value),
    );
  }
}

export function loadFormFieldsFromLocalStorage(): any {
  let initialFieldValues: Record<string, string | Array<string>> = {};
  if (typeof window !== 'undefined' && localStorage) {
    const storedFieldsDataArrayStr = localStorage.getItem('form_fields');
    if (storedFieldsDataArrayStr) {
      const storedFieldsDataArray = JSON.parse(storedFieldsDataArrayStr);
      initialFieldValues = Object.fromEntries(
        storedFieldsDataArray.map(([fieldName, value, type]) => {
          let finalValue = value;
          if (type === 'array') {
            finalValue = JSON.parse(finalValue);
          } else if (type !== 'string') {
            throw new Error('Got unexpected type: ' + type);
          }
          return [fieldName, finalValue];
        }),
      );
    }
  }
  return initialFieldValues;
}

/**
 * Takes any number of arguments, filters "false" ones (false, null, undefined) and joins the rest with spaces.
 * Use this to easily add multiple classes together, where some of them should be added conditionally.
 *
 * Example: clsx('someClass', condition && otherClass, potentiallyUndefinedClass)
 *
 * Function inspired by https://github.com/lukeed/clsx
 */
export const clsx = (...args: Array<string | false | null | undefined>): string =>
  args.filter(Boolean).join(' ');

export function setCookie(
  name: string,
  value: string,
  {
    expires = 365,
    sameSite = 'none',
    ...extraOptions
  }: {
    expires?: number;
    sameSite?: 'strict' | 'lax' | 'none';
  },
): void {
  const secure = location ? location.protocol === 'https:' : true;

  const cookieOptions = { expires, sameSite, secure, ...extraOptions };

  // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === 'none') {
    Cookies.set(name + '-legacy', value, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(name, value, cookieOptions);
}

export function getCookieValue(name: string): string | undefined {
  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(name + '-legacy');
  }
  return cookieValue;
}

export function isDeviceMobile(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const mobileRegex = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/;
  return (
    mobileRegex.test(window.navigator.appVersion) || mobileRegex.test(window.navigator.userAgent)
  );
}

export function slugify(str: string): string {
  return str
    .replace(/\s/gi, '-')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

/**
 * Truncates a string depending on the maxLength and replace excess text with '...'
 */
export function truncateText(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const strParts = str.match(/(^|[^A-zÀ-ú\-])+[A-zÀ-ú\-]+/g) || [];
  let truncateStr = '';
  for (const strPart of strParts) {
    if (truncateStr.length + strPart.length + 3 > maxLength) {
      break;
    }
    truncateStr += strPart;
  }
  return truncateStr + '...';
}

export async function wait(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getUtmQueryStringFromUtmParams(
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null,
  utmTerm: string | null,
  utmContent: string | null,
) {
  const utmQuery = [
    utmSource ? 'utm_source=' + utmSource : '',
    utmMedium ? 'utm_medium=' + utmMedium : '',
    utmCampaign ? 'utm_campaign=' + utmCampaign : '',
    utmTerm ? 'utm_term=' + utmTerm : '',
    utmContent ? 'utm_content=' + utmContent : '',
  ]
    .filter(Boolean)
    .join('&');

  return utmQuery;
}

export function removeParamsFromQueryParams(paramsToRemove: Array<string>) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlSearchParamsWithParamsRemoved = new URLSearchParams(urlSearchParams);
  for (const paramToRemove of paramsToRemove) {
    urlSearchParamsWithParamsRemoved.delete(paramToRemove);
  }
  const newQuery = urlSearchParamsWithParamsRemoved.toString();
  const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');
  history.replaceState(null, '', newUrlPathAndQuery);
}

export type StrPartPreprocesser = (str: string) => React.ReactNode;

export function wrapStrPartsBySplitter(
  str: string,
  splitter: string | RegExp,
  wrapStrPart: (strPart: React.ReactNode, index: number) => React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const strParts = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  if (strParts.length % 2 === 0) {
    throw new Error(
      `String incorrectly formatted, got even number of parts after split. splitRegex: ${splitter}   str: ${str}`,
    );
  }
  return strParts.map((string, i) => (i % 2 !== 0 ? wrapStrPart(string, i) : string));
}

export function replaceSplitterWithEl(
  str: string,
  splitter: string | RegExp,
  el: React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const splitStr = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  const splitStrWithEls: Array<React.ReactNode> = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithEls.push(<React.Fragment key={i * 2}>{splitStr[i]}</React.Fragment>);
    splitStrWithEls.push(<React.Fragment key={i * 2 + 1}>{el}</React.Fragment>);
  }
  splitStrWithEls.pop();
  return splitStrWithEls;
}

export function replaceNewLinesWithBr(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  return replaceSplitterWithEl(str, '\n', <br></br>, preprocessStrPart);
}

export function wrapSquareBracketedWithSerif(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(
    str,
    /[\[\]]/,
    (el, i) => (
      <span key={i} style={{ fontFamily: 'var(--font-family-secondary)' }}>
        {el}
      </span>
    ),
    preprocessStrPart,
  );
}

export function wrapCurlyBracedInItalic(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(
    str,
    /[{}]/,
    (el, i) => (
      <span
        key={i}
        style={{ fontFamily: 'var(--font-family-secondary-italic)', fontStyle: 'italic' }}
      >
        {el}
      </span>
    ),
    preprocessStrPart,
  );
}

export function removeCurlyBracesAndSquareBrackets(str: string) {
  return str.replace(/[{}\[\]]/g, '');
}

export function checkIsInternalUrl(url: string): boolean {
  if (url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(?:\/|$)`))) {
    return true;
  } else if (url.match(/^\w+:\/\//)) {
    return false;
  } else if (url.match(/^(tel|fax|mailto):/)) {
    return false;
  } else {
    return true;
  }
}

export function getInternalUrlPath(url: string): string {
  if (!checkIsInternalUrl(url)) {
    throw new Error('Called getInternalUrlPath with a non internal url: ' + url);
  }
  if (!url.startsWith('http')) {
    return url;
  }
  const match = url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(.*)`));
  if (match === null) {
    throw new Error('Got null match from supposedly internal url: ' + url);
  }
  const path = match[1];
  if (path === '') {
    return '/';
  }
  if (!path.startsWith('/')) {
    throw new Error("Url path should start with slash but doesn't: " + url);
  }

  return path;
}

export function checkUrlsMatch(url1: string, url2: string): boolean {
  let url1Cleaned = url1.trim();
  let url2Cleaned = url2.trim();
  if (url1Cleaned.endsWith('/')) {
    url1Cleaned = url1Cleaned.substring(0, url1Cleaned.length - 1);
  }
  if (url2Cleaned.endsWith('/')) {
    url2Cleaned = url2Cleaned.substring(0, url2Cleaned.length - 1);
  }

  return url1Cleaned === url2Cleaned;
}

type UrlJoinOptions = { leadingSlash?: boolean; trailingSlash?: boolean };
/**
 * Joins url parts trying turn them into a valid url.
 *
 * Options (last param):
 * - leadingSlash, only useful when url has no protocol (default true):
 *      If true, forces url to start with leading slash.
 *      If false, forces url to start without leading slash.
 * - trailingSlash (default true):
 *      If true, forces url to end with trailing slash.
 *      If false, forces url to end without trailing slash.
 *
 * Examples:
 * CALL: urlJoin()
 * RESULT: ''
 *
 * CALL: urlJoin('a')
 * RESULT: '/a/'
 *
 * CALL: urlJoin('a', { leadingSlash: false })
 * RESULT: 'a/'
 *
 * CALL: urlJoin('a', { trailingSlash: false })
 * RESULT: '/a'
 *
 * CALL: urlJoin('/a/')
 * RESULT: '/a/'
 *
 * CALL: urlJoin('/a/', { leadingSlash: false })
 * RESULT: 'a/'
 *
 * CALL: urlJoin('/a/', { trailingSlash: false })
 * RESULT: '/a'
 *
 * CALL: urlJoin('/a', '?b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a/', '?b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a', '?b=c', { trailingSlash: false })
 * RESULT: '/a?b=c'
 *
 * CALL: urlJoin('/a/', '?b=c', { trailingSlash: false })
 * RESULT: '/a?b=c'
 *
 * CALL: urlJoin('http://www.google.com/', '/a/', '/b/cd/', 'e/f', '///gh/ijk///', '?foo=123', '?bar=baz', '&xpto=poiu')
 * RESULT: 'http://www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu'
 *
 * CALL: urlJoin('http://www.google.com/', '/a/', '/b/cd/', 'e/f', '///gh/ijk///', '?foo=123', '?bar=baz', '&xpto=poiu', { leadingSlash: false, trailingSlash: false})
 * RESULT: 'http://www.google.com/a/b/cd/e/f/gh/ijk?foo=123&bar=baz&xpto=poiu'
 *
 * CALL: urlJoin(' https://www.google.com/ ', '  /a/', '  /b/cd/ ', '  e/f  ', ' ///gh/ijk///  ', '    ?foo=123 ', ' ?bar=baz ', ' &xpto=poiu   ')
 * RESULT: 'https://www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu'
 */
export function urlJoin(...urlParts: Array<string> | [...Array<string>, UrlJoinOptions]): string {
  let options = {
    leadingSlash: true,
    trailingSlash: true,
  };
  let cleanUrlParts: Array<string>;
  if (typeof urlParts[urlParts.length - 1] === 'object') {
    options = {
      ...options,
      ...(urlParts[urlParts.length - 1] as UrlJoinOptions),
    };
    cleanUrlParts = urlParts.slice(0, -1) as Array<string>;
  } else {
    cleanUrlParts = urlParts as Array<string>;
  }
  if (options.leadingSlash === undefined) {
    options.leadingSlash = true;
  }
  if (options.trailingSlash === undefined) {
    options.trailingSlash = true;
  }

  if (cleanUrlParts.some(str => typeof str !== 'string')) {
    throw new TypeError('Url parts must be a strings. Received ' + JSON.stringify(cleanUrlParts));
  }
  const strArray = [...cleanUrlParts].map(str => str.trim());

  const resultArray: Array<string> = [];
  if (strArray.length === 0) {
    return '';
  }

  // If the first part is a plain protocol, we combine it with the next part.
  if (strArray[0].match(/^[^/:]+:\/*$/) && strArray.length > 1) {
    strArray[0] = strArray.shift() + strArray[0];
  }

  // There must be two or three slashes in the file protocol, two slashes in anything else.
  if (strArray[0].match(/^file:\/\/\//)) {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1:///');
  } else {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1://');
  }

  for (let i = 0; i < strArray.length; i++) {
    let component = strArray[i];

    if (typeof component !== 'string') {
      throw new TypeError('Url must be a string. Received ' + component);
    }

    if (component === '') {
      continue;
    }

    if (i > 0) {
      // Removing the starting slashes for each component but the first.
      component = component.replace(/^[\/]+/, '');
    }
    if (i < strArray.length - 1) {
      // Removing the ending slashes for each component but the last.
      component = component.replace(/[\/]+$/, '');
    } else {
      // For the last component we will combine multiple slashes to a single one.
      component = component.replace(/[\/]+$/, '/');
    }

    resultArray.push(component);
  }

  let str = resultArray.join('/');
  // Each input component is now separated by a single slash except the possible first plain protocol part.

  // remove trailing slash before parameters or hash
  str = str.replace(/\/(\?|&|#[^!])/g, '$1');

  // replace ? in parameters with &
  const parts = str.split('?');
  str = parts.shift() + (parts.length > 0 ? '?' : '') + parts.join('&');

  // Only look at leadingSlash option if there is no protocol
  if (!str.match(/^([^/:]+):\//)) {
    if (options.leadingSlash) {
      if (!str.startsWith('/')) {
        str = '/' + str;
      }
    } else {
      if (str.startsWith('/')) {
        str = str.substring(1);
      }
    }
  }

  if (options.trailingSlash) {
    if (str.includes('?')) {
      str = str.split('?').join('/?');
    } else if (!str.endsWith('/')) {
      str = str + '/';
    }
  } else {
    if (!str.includes('?') && str.endsWith('/')) {
      str = str.substring(0, str.length - 1);
    }
  }

  return str;
}

export function getUsedViewCategories(allViews: Array<{ categories?: Array<ViewCategory> }>) {
  return uniqBy(flatten(allViews.map(view => view.categories || [])), '_id');
}

export function getFirstTextContentBlockAsString(contentBuilder: Array<Module>): string | null {
  for (const contentBlock of contentBuilder) {
    if (contentBlock._type === 'textContent') {
      return getPortableTextAsString(contentBlock._rawTextEditor);
    }
    if (contentBlock._type === 'textWithImage') {
      return getPortableTextAsString(contentBlock._rawBody);
    }
    if (contentBlock._type === 'textWithPullQuote') {
      return getPortableTextAsString(contentBlock._rawBody);
    }
  }
  return null;
}

export function getFullWidthModules(data: Array<Module>, index: number): Array<string> {
  const fullWidthModules = ['iconGrid', 'featuredProjects', 'ctaBanner'];

  const moduleToFind = data[index];

  if (moduleToFind._type === 'featuredViews' && moduleToFind.withDarkerBackground) {
    fullWidthModules.push('featuredViews');
  }

  return fullWidthModules;
}

export function getRandomColor() {
  return TRANSITION_COLORS[Math.floor(Math.random() * TRANSITION_COLORS.length)];
}

export function assert(condition: boolean, errorMessage: string): asserts condition {
  if (!condition) {
    throw new Error(errorMessage);
  }
}
