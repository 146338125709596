import { LiveQueryProvider } from '@sanity/preview-kit';
import React, { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../context/locationContext';
import { useSanityConfigData } from '../utils/sanity';
import PreviewErrorScreen from './PreviewErrorScreen';
import PreviewIndicator from './PreviewIndicator';
import PreviewInfoScreen from './PreviewInfoScreen';
import { usePreviewState } from './PreviewStateContext';
import { getSanityPreviewClient } from './previewUtils';

export default function PreviewConnectorProvider({ children }) {
  const sanityConfigData = useSanityConfigData();
  const location = useContext(LocationContext);
  const { previewActive, setPreviewActive, previewIsLoading } = usePreviewState();
  const [hasSanityPreviewParam, setHasSanityPreviewParam] = useState(false);
  const [failedToAccessProject, setFailedToAccessProject] = useState(false);
  const [previewPaused, setPreviewPaused] = useState(false);

  const client = React.useMemo(
    () => (hasSanityPreviewParam ? getSanityPreviewClient(sanityConfigData) : null),
    [hasSanityPreviewParam],
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sanityPreviewParam = urlSearchParams.get('_sanityPreview');

    if (sanityPreviewParam !== null) {
      setHasSanityPreviewParam(true);
    } else if (previewActive) {
      urlSearchParams.set('_sanityPreview', 'true');
      const newQuery = urlSearchParams.toString();
      const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');
      history.replaceState(null, '', newUrlPathAndQuery);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (client && !failedToAccessProject) {
      client.projects
        .getById(sanityConfigData.projectId)
        .then(() => {
          setPreviewActive(true);
        })
        .catch(() => {
          return setFailedToAccessProject(true);
        });
    }
  }, [client, failedToAccessProject]);

  // Auto pause after 5 min
  useEffect(() => {
    if (!previewPaused) {
      const pauseTimeout = setTimeout(() => {
        setPreviewPaused(true);
      }, 5 * 60 * 1000);
      return () => {
        return clearTimeout(pauseTimeout);
      };
    }
    return undefined;
  }, [previewPaused]);

  if (failedToAccessProject) {
    return (
      <PreviewErrorScreen
        title="Not logged into Sanity project"
        message={
          <>
            Go to the backoffice and login first, then use the button below to retry.<br></br>
            If you didn't mean to open the preview, remove the <code>_sanityPreview=true</code> from
            the url.
          </>
        }
        onTryAgain={() => {
          setFailedToAccessProject(false);
        }}
      ></PreviewErrorScreen>
    );
  }

  if (!previewActive) {
    // Return the regular children with no draft documents
    return children;
  }

  if (previewPaused) {
    return (
      <PreviewInfoScreen
        title="Preview paused"
        message={
          <>Preview has paused in order to avoid sending unecessary requests to Sanity's API.</>
        }
        onClick={() => {
          setPreviewPaused(false);
        }}
        btnLabel="Unpause"
      ></PreviewInfoScreen>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <LiveQueryProvider client={client!} logger={console}>
      {children}
      {!previewIsLoading && <PreviewIndicator />}
    </LiveQueryProvider>
  );
}
