import { useLocation } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { getRandomColor } from '../utils/utils';

const firstBackgroundColor = getRandomColor();

// Total duration, counting with entry and exit of the green curtain
export const PAGE_TRANSITION_DURATION_MS = 800;

const PageTransition = ({ children }) => {
  const location = useLocation();
  const [renderMotionDiv, setRenderMotionDiv] = useState(false);
  const firstAnimation = useRef(true);
  useEffect(() => {
    setRenderMotionDiv(true);
  }, []);

  const [backgroundColor, setBackgroundColor] = useState(firstBackgroundColor);
  const isNextAnimationTheEndOfPageTransition = useRef(false);

  return (
    <AnimatePresence
      mode="wait"
      onExitComplete={() => {
        isNextAnimationTheEndOfPageTransition.current = true;
      }}
    >
      <React.Fragment key={location.pathname}>
        {renderMotionDiv && (
          <motion.div
            // Initial/animate happens when the page enters,
            // so it's actually the exit of the green curtain.
            // It starts filling the screen, then scroll down outside the screen,
            // and finally jumps back to above the screen with opacity 0.
            onAnimationComplete={(...args) => {
              if (isNextAnimationTheEndOfPageTransition.current) {
                setBackgroundColor(getRandomColor());
                isNextAnimationTheEndOfPageTransition.current = false;
              }
              firstAnimation.current = false;
            }}
            initial={{
              top: firstAnimation.current ? '-100vh' : '0vh',
              opacity: firstAnimation.current ? 0 : 1,
            }}
            animate={{
              top: firstAnimation.current ? '-100vh' : ['0vh', '100vh', '-100vh'],
              opacity: firstAnimation.current ? 0 : [1, 1, 0],
              transition: {
                ease: 'easeIn',
                duration: PAGE_TRANSITION_DURATION_MS / 2 / 1000,
                times: firstAnimation.current ? [1] : [0, 1, 1],
              },
            }}
            // Exit is the exit of the page, so it's actually the entry of the green curtain.
            // It starts by making the opacity 1 above the screen, then scroll down until it fills the screen.
            exit={{
              top: ['-100vh', '0vh'],
              opacity: [1, 1],
              transition: {
                ease: 'easeOut',
                duration: PAGE_TRANSITION_DURATION_MS / 2 / 1000,
                times: [0, 1],
              },
            }}
            style={{
              position: 'fixed',
              background: backgroundColor,
              width: '100%',
              height: '100vh',
              zIndex: 9999,
              pointerEvents: 'none',
            }}
          ></motion.div>
        )}
        {children}
      </React.Fragment>
    </AnimatePresence>
  );
};

export default PageTransition;
